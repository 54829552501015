/*
 * @Date: 2022-03-16 15:32:44
 * @LastEditTime: 2022-03-28 10:56:05
 * @FilePath: \hc_website\public\ipconfig.js
 */
const config = {
  // ip: "http://192.168.31.51:8084",
  // ip: "http://182.92.107.145:83",
  // ip: "http://47.92.255.160:29900",
  ip: "/hc_website",
  phone: "010-6170-1899",
  address: "北京市海淀区上地十街辉煌国际1号楼1902",
  email: "admin@bimquan.com",
};
export default config;
